<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row class="match-height">
        <div class="col-md-12">
          <div>
            <div class="p-2">
              <router-link to="/admin/finance/receipt">
                <b-button
                  variant="primary"
                  class="mr-50 col-md-2"
                >
                  {{ $t('returnToList') }}
                </b-button>
              </router-link>
            </div>
          </div>
        </div>
      </b-row>
      <b-row class="match-height col-md-12">
        <div class="row match-height ">
          <div class="col-md-4 mb-0">
            <div class="card p-2">
              <div class="header-cardx p-2 mb-2">
                <h4 class="mb-0">
                  {{ $t('details') }}
                </h4>
              </div>
              <div>
                <div class="d-flex align-items-left mb-1">
                  <div>
                    <p class="mb-0 font-weight-bolder text-left mr-2">
                      {{ $t('receiptNumber') }} :
                    </p>
                  </div>

                  <div>
                    {{ items.billnumber }}
                  </div>
                </div>

                <div class="d-flex align-items-left mb-1">
                  <div>
                    <p class="mb-0 font-weight-bolder text-left mr-2">
                      {{ $t('status') }} :
                    </p>
                  </div>

                  <div
                    v-if="items.statusbill === 1"
                    class="w-80 badge badge-light-success "
                  >
                    {{ $t('operationCompleted') }}
                  </div>
                  <div
                    v-else-if="items.statusbill === 2"
                    class="w-50 badge badge-light-danger col-md-3"
                  >
                    {{ $t('cancelled') }}
                  </div>
                  <div
                    v-else
                    class="w-50 badge badge-light-primary col-md-3"
                  >
                    {{ $t('pendingReview') }}
                  </div>
                </div>
                <div class="d-flex align-items-left mb-1">
                  <div>
                    <p class="mb-0 font-weight-bolder text-left mr-2">
                      {{ $t('transactionDate') }} :
                    </p>
                  </div>

                  <div>
                    {{ time(items.created_at) }}
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="col-md-8 mb-0">
            <div>
              <div class="card p-2">
                <div class="header-cardx p-2 mb-2">
                  <h4 class="mb-0">
                    {{ $t('listReceipts') }}
                  </h4>
                </div>
                <div class="table-container">
                  <table class="styled-table mt-n25">
                    <thead>
                      <tr>
                        <th scope="col">
                          {{ $t('transactionDate') }}
                        </th>
                        <th
                          class="text-left"
                          scope="col"
                        >
                          {{ $t('receiptNumber') }}
                        </th>
                        <th scope="col">
                          {{ $t('name') }}
                        </th>
                        <th scope="col">
                          {{ $t('TaxID') }}
                        </th>
                        <th
                          class="text-left"
                          scope="col"
                        >
                          {{ $t('quantity') }}
                        </th>
                        <th scope="col">
                          {{ $t('status') }}
                        </th>
                      </tr>
                    </thead>
                    <thead>
                      <tr style="background: #fff; color: black;">
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black;"
                        >
                          {{ time(items.created_at) }}
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black;"
                        >
                          {{ items.billnumber }}
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black;"
                        >
                          {{ items.texfullname }}
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black;"
                        >
                          {{ items.texnumber }}
                        </th>
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black;"
                        >
                          <span class="text-success">+{{ Commas(arritem.reduce((total, data) => total + data.sumitem, 0))
                          }} ฿</span>

                        </th>
                        <th
                          class="text-center"
                          scope="col"
                          style="background: #fff; color: black;"
                        >

                          <span
                            v-if="items.statusbill === 1"
                            class="badge badge-light-success badge-pill"
                          > {{
                            $t('operationCompleted') }} </span>
                          <span
                            v-else-if="items.statusbill === 2"
                            class="badge badge-light-danger badge-pill"
                          > {{
                            $t('cancelled') }} </span>
                          <span
                            v-else
                            class="badge badge-light-primary badge-pill "
                          > {{ $t('pendingReview') }} </span>

                        </th>

                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
      <b-row class="match-height">
        <div class="row match-height">
          <div
            v-if="items.statusbill === 1"
            class="col-md-12 mb-2"
          >
            <div>

              <b-button
                variant="warning"
                class="mr-50 col-md-2"
                @click="cancelsubmit()"
              >
                {{ $t('ChangeToWaitVerify') }}
              </b-button>

              <b-button
                v-if="items.statusprint"
                variant="danger"
                class="mr-50 col-md-2"
                @click="submitprint()"
              >
                {{ $t('recordNotprinted') }}
              </b-button>
              <b-button
                v-else
                variant="warning"
                class="mr-50 col-md-2"
                @click="submitprint()"
              >
                {{ $t('recordPrinted') }}
              </b-button>

              <b-button
                variant="secondary"
                class="mr-50 col-md-2"
                @click="printbill()"
              >
                {{ $t('print') }}
              </b-button>
              <b-button
                variant="danger"
                class="mr-50 col-md-2 mb-1"
                @click="cancelbill()"
              >
                {{ $t('cancelOrder') }}
              </b-button>
            </div>
          </div>
          <div
            v-else-if="items.statusbill === 2"
            class="col-md-12 mb-2"
          >
            <div class="gap-5">
              <!-- <b-button
                variant="primary"
                class="mr-50 col-md-2 mb-1"
                @click="Submit()"
              >
                {{ $t('save') }}
              </b-button> -->

              <!-- <b-button
                variant="success"
                class="mr-50 col-md-2 mb-1"
                @click="Submitokay()"
              >
                {{ $t('ApproveList') }}
              </b-button> -->

              <b-button
                v-if="items.statusprint"
                variant="danger"
                class="mr-50 col-md-2 mb-1"
                @click="submitprint()"
              >
                {{ $t('recordNotprinted') }}
              </b-button>
              <b-button
                v-else
                variant="warning"
                class="mr-50 col-md-2 mb-1"
                @click="submitprint()"
              >
                {{ $t('recordPrinted') }}
              </b-button>

              <b-button
                variant="secondary"
                class="mr-50 col-md-2 mb-1"
                @click="printbill()"
              >
                {{ $t('print') }}
              </b-button>
            </div>
          </div>
          <div
            v-else
            class="col-md-12 mb-2"
          >
            <div class="gap-5">
              <b-button
                variant="primary"
                class="mr-50 col-md-2 mb-1"
                @click="Submit()"
              >
                {{ $t('save') }}
              </b-button>

              <b-button
                variant="success"
                class="mr-50 col-md-2 mb-1"
                @click="Submitokay()"
              >
                {{ $t('ApproveList') }}
              </b-button>

              <b-button
                v-if="items.statusprint"
                variant="danger"
                class="mr-50 col-md-2 mb-1"
                @click="submitprint()"
              >
                {{ $t('recordNotprinted') }}
              </b-button>
              <b-button
                v-else
                variant="warning"
                class="mr-50 col-md-2 mb-1"
                @click="submitprint()"
              >
                {{ $t('recordPrinted') }}
              </b-button>

              <b-button
                variant="secondary"
                class="mr-50 col-md-2 mb-1"
                @click="printbill()"
              >
                {{ $t('print') }}
              </b-button>
              <b-button
                variant="danger"
                class="mr-50 col-md-2 mb-1"
                @click="cancelbill()"
              >
                {{ $t('cancelOrder') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-row>
      <b-row class="card mt-2">
        <div class="d-flex align-items-center">
          <b-col class="col-md-6">
            <div class="p-2 mb-0">
              <a href="/"><img
                src="/img/logo_top.063e25a7.png"
                height="35"
                class=""
              ></a>
            </div>
          </b-col>
          <b-col class="col-md-6 align-items-right">
            <div class="p-2 align-items-center mb-0">
              <h4 class="mb-0 font-weight-bolder text-right mr-0">
                {{ $t('receiptMoney') }}
              </h4>
              <p class="mb-0 font-weight-bolder text-right mr-0">
                Page 1 of 1
              </p>
            </div>
          </b-col>
        </div>
        <div class="d-flex align-items-center col-md-6">
          <div class="p-2 ">
            <p class="mb-0 font-weight-bolder text-left mr-2">
              100/110 ซอยพุทธมณฑลสาย 2 ซอย 24 แขวงศาลาธรรมสพน์ เขตทวีวัฒนา กรุงเทพมหานคร
              เลขประจำตัวผู้เสียภาษี 0105565064138 / สำนักงานใหญ่
            </p>
          </div>
        </div>

        <div class="d-flex align-items-center col-md-12 text-mobile">

          <div class="col-md-6 col-lg-6 col-sm-12">
            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('customerCode') }}
                </template>
                <div class="mt-50">
                  {{ items.username }}
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('name') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="texfullname"
                  rules="required"
                >
                  <b-form-input
                    v-model="texfullname"
                    :state="errors.length > 0 ? false : null"
                  />
                  <span class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-for="h-address"
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('address') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="addresstex"
                  rules="required"
                >
                  <b-form-textarea
                    id="h-address"
                    v-model="addresstex"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                  />
                  <span class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-for="h-purchase-order"
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('mobileNumber') }}
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="purchase-order"
                  rules="required"
                >
                  <b-form-input
                    id="h-purchase-order"
                    v-model="telephone"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                  />
                  <span class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-for="h-purchase-order"
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  <span class="text-danger">*</span> {{ $t('TaxID') }}

                </template>
                <validation-provider
                  #default="{ errors }"
                  name="texnumber"
                  rules="required"
                >
                  <b-form-input
                    v-model="texnumber"
                    :state="errors.length > 0 ? false : null"
                  />
                  <span class="text-danger">{{ errors[0] ? $t('requiredField') : '' }}</span>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('paymentSlipnumber') }}
                </template>
                <div class="mt-50">
                  <router-link
                    :to="{ name: 'shipment_payment_edit', params: { id: items._id } }"
                    target="_blank"
                  >
                    {{ items.itemnumber_text }}
                  </router-link>
                  <!-- {{ items._id }} -->
                  <!-- <b-link :to="{name: 'admin-user-type-edit', params: {id: items._id}}">
                  {{ items.itemnumber_text }}
                </b-link> -->

                </div>
              </b-form-group>
            </b-col>

          </div>

          <div class="col-md-6">
            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('date') }}
                </template>
                <div class="mt-50">
                  {{ times(items.updated_at) }}
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('receiptNumber') }}
                </template>
                <div class="mt-50">
                  {{ items.billnumber }}
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('salesperson') }}
                </template>
                <div class="mt-50">
                  -
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('Payment') }}
                </template>
                <div class="mt-50">
                  {{ $t('cash') }}
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('dueDate') }}
                </template>
                <div class="mt-50">
                  -
                </div>
              </b-form-group>
            </b-col>

            <b-col
              md="12"
              lg="12"
              sm="12"
            >
              <b-form-group
                label-cols="4"
                label-cols-lg="4"
                label-cols-md="4"
              >
                <template v-slot:label>
                  {{ $t('shippingMethod') }}
                </template>
                <div class="mt-50">
                  -
                </div>
              </b-form-group>
            </b-col>

          </div>
        </div>

        <div class="align-items-center col-md-12 p-2 table-container">
          <table style="width: 100%; border: 1px solid #000000;">
            <tr
              style="width: 100%; background-color: #dddddd;"
              class=""
            >
              <th
                style="width: 10%; border: 1px solid #000000;"
                class="text-center"
              >
                <p>
                  {{ $t('productCode') }}
                </p>
                <p>
                  Code
                </p>
              </th>
              <th
                style="width: 50%; border: 1px solid #000000;"
                class="text-center"
              >
                <p>
                  {{ $t('description') }}
                </p>
                <p>
                  Description
                </p>
              </th>
              <th
                style="width: 10%; border: 1px solid #000000;"
                class="text-center"
              >
                <p>
                  {{ $t('quantity') }}
                </p>
                <p>
                  Quantity
                </p>
              </th>
              <th
                style="width: 10%; border: 1px solid #000000;"
                class="text-center"
              >
                <p>
                  {{ $t('unitPrice') }}
                </p>
                <p>
                  Unit
                </p>
              </th>
              <th
                style="width: 10%; border: 1px solid #000000;"
                class="text-center"
              >
                <p>
                  {{ $t('discount') }}
                </p>
                <p>
                  Discount
                </p>
              </th>
              <th
                style="width: 10%; border: 1px solid #000000;"
                class="text-center"
              >
                <p>
                  {{ $t('amountOfmoney') }}
                </p>
                <p>
                  Amount
                </p>
              </th>
            </tr>
            <tr
              v-for="(item, index) in arritem"
              :key="index"
            >
              <td
                style="width: 10%; vertical-align: top; border: 1px solid #000000;"
                class="text-center"
              >
                {{ item.code }}
                <!-- TR-1001 -->
              </td>
              <td
                style="width: 50%; vertical-align: top; border: 1px solid #000000;"
                class="text-left"
              >
                {{ item.description }}
              </td>
              <td
                style="width: 10%; vertical-align: top; border: 1px solid #000000;"
                class="text-right"
              >
                1
              </td>
              <td
                style="width: 10%; vertical-align: top; border: 1px solid #000000;"
                class="text-right"
              >
                {{ Commas(item.payment) }}
              </td>
              <td
                style="width: 10%; vertical-align: top; border: 1px solid #000000;"
                class="text-right"
              >
                {{ Commas(item.difference) }}
              </td>
              <td
                style="width: 10%; vertical-align: top; border: 1px solid #000000; "
                class="text-right"
              >
                {{ Commas(item.sumitem) }}
              </td>
            </tr>

            <tr>
              <td
                style="vertical-align: top; border: 1px solid #000000;"
                class="text-left"
                colspan="3"
              >
                {{ convertToThaiBaht(arritem.reduce((total, data) => total + data.sumitem, 0)) }}
              </td>
              <td
                style="vertical-align: top; border: 1px solid #000000;"
                colspan="3"
              >
                <div class="d-flex justify-content-between">
                  <span class="text-left">
                    <b>
                      {{ $t('netAmounts') }}
                    </b>
                  </span>
                  <span class="text-right">
                    <b>
                      {{ Commas(arritem.reduce((total, data) => total + data.sumitem, 0)) }}
                    </b>
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="d-flex align-items-center justify-content-start p-2" />
      </b-row>
      <div class="d-flex align-items-center justify-content-start p-2" />
    </validation-observer>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'
import {
  BFormInput, BFormGroup, BFormTextarea, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {

    BFormInput,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    // vSelect,
    // BFormRadio,
    // BFormCheckbox,
    // BPagination,
    // BLink,
    // BModal,
    // BTable,
    // BSpinner,
    BButton,
  },
  data() {
    return {
      search: '',
      isActive: 'all',
      selectedRadio: 'active',
      optionsRadio: [
        { text: 'All', value: 'all' },
        { text: 'Car', value: 'car' },
        { text: 'Ship', value: 'ship' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      // fields: [
      //   {
      //     key: 'billnumber', label: 'หมายเลข', thStyle: { width: '10%' }, thClass: 'text-center',
      //   },
      //   {
      //     key: 'itemnumber_text', label: 'เลขที่ใบส่งของ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
      //   },
      //   {
      //     key: 'username', label: 'ลูกค้า', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'created_at', label: 'วันที่ทำรายการ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'updated_at', label: 'วันที่อนุมัติ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'texfullname', label: 'นาม', sortable: false, thStyle: { width: '15%' }, thClass: 'text-center',
      //   },
      //   {
      //     key: 'filetex', label: 'เอกสาร', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'typetex', label: 'ประเภทลูกค้า', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'payment', label: 'จำนวน', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-right',
      //   },
      //   {
      //     key: 'statusbill', label: 'สถานะ', sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      //   {
      //     key: 'actions', label: 'actions', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
      //   },
      // ],
      items: [
        // {
        //   id: 1,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/7a796ac0.jpg',
        //   customerType: 'บุคคลธรรมดา',
        //   amount: 100000,
        //   status: 'waiting',
        // },

        // {
        //   id: 2,
        //   number: 'GUSR-0001',
        //   deliveryNumber: 'GUSR-0001',
        //   customer: 'GUSR-0001',
        //   transactionDate: 'GUSR-0001',
        //   approvalDate: '-',
        //   noun: 'บริษัท ชิดา คอมเมอร์เชียล (ประเทศไทย) จำกัด',
        //   document: 'https://plmcdn.azureedge.net/upload/images/receipt/72655903.jpg',
        //   customerType: 'นิติบุคคล',
        //   amount: 100000,
        //   status: 'success',
        // },
      ],
      pic: null,

      total: 0,
      notpaid: 0,
      waitingforcheck: 0,
      waitingforpayment: 0,
      paid: 0,
      OrderSuccess: 0,
      completetrack: 0,
      completewarehouse: 0,
      cancel: 0,
      ID: this.$route.params.id,
      texfullname: null,
      addresstex: null,
      telephone: null,
      texnumber: null,
      arritem: [],
      required,
    }
  },
  mounted() {
    this.getData()
    // this.GetNotify()
    // this.GetOrderAll(0)
  },
  methods: {
    // Commas(num) {
    //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    // },
    time(data) {
      moment.locale('th')
      return moment(data).format('DD MMMM YYYY HH:mm:ss')
    },
    times(data) {
      return moment(data).format('DD/MM/YYYY')
    },
    GetNotify() {
      this.$http
        .get('/order/Notify')
        .then(response => {
          // console.log(response.data)
          this.total = response.data.total
          this.waitingforcheck = response.data.waitingforcheck
          this.waitingforpayment = response.data.waitingforpayment
          this.paid = response.data.paid
          this.OrderSuccess = response.data.OrderSuccess
          this.completetrack = response.data.completetrack
          this.completewarehouse = response.data.completewarehouse
          this.cancel = response.data.cancel
        })
        .catch(error => console.log(error))
    },
    cancelbill() {
      this.$bvModal.msgBoxConfirm('ต้องการยกเลิกใบเสร็จ?', {
        title: 'ยืนยัน',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const obj = {
              _id: this.ID,
              statusbill: 2,
            }
            this.$http.post('/Billpay/update', obj)
              .then(response => {
                if (response.data) {
                  this.getData()
                }
              })
              .catch(err => {
                console.log(err)
                this.SwalError('ยกเลิกใบเสร็จไม่สำเร็จ')
              })
          }
        })
    },
    async getData() {
      try {
        const params = {
          id: this.ID,
        }
        const { data: res } = await this.$http.get('/Billpay/getdataOne', { params })
        this.items = res
        const arritem = []
        if (this.items.difference && this.items.promotion) {
          console.log(this.items.payment - this.items.promotion.discount_pro - this.items.Service_fee)
          // console.log('1')
          // const item = (Number(this.items.payment) - this.items.promotion.discount_pro) - this.items.Service_fee + Number(this.items.difference)
          arritem.push({
            code: 'TR-1001', description: 'ค่าขนส่งสินค้า', quantity: 1, payment: (this.items.payment - this.items.promotion.discount_pro - this.items.Service_fee) + this.items.difference, difference: this.items.difference, sumitem: (this.items.payment - this.items.promotion.discount_pro - this.items.Service_fee),
          })
        } else if (this.items.difference) {
          arritem.push({
            code: 'TR-1001', description: 'ค่าขนส่งสินค้า', quantity: 1, payment: (this.items.payment - this.items.Shipping_cost - this.items.Service_fee) + this.items.difference, difference: this.items.difference, sumitem: (this.items.payment - this.items.Shipping_cost - this.items.Service_fee),
          })
        } else if (this.items.coupon && this.items.promotion) {
          console.log('2')
          arritem.push({
            code: 'TR-1001', description: 'ค่าขนส่งสินค้า', quantity: 1, payment: this.items.coupon.old_payment, difference: 0, sumitem: this.items.coupon.old_payment,
          })
        } else if (this.items.coupon) {
          console.log('3')
          arritem.push({
            code: 'TR-1001', description: 'ค่าขนส่งสินค้า', quantity: 1, payment: this.items.coupon.old_payment, difference: 0, sumitem: this.items.coupon.old_payment,
          })
        } else if (this.items.promotion) {
          console.log('4')
          arritem.push({
            code: 'TR-1001', description: 'ค่าขนส่งสินค้า', quantity: 1, payment: (this.items.payment - this.items.promotion.discount_pro) - this.items.Service_fee, difference: 0, sumitem: (this.items.payment - this.items.promotion.discount_pro) - this.items.Service_fee,
          })
        } else {
          console.log('5')
          arritem.push({
            code: 'TR-1001', description: 'ค่าขนส่งสินค้า', quantity: 1, payment: (this.items.payment - this.items.Shipping_cost - this.items.Service_fee), difference: 0, sumitem: (this.items.payment - this.items.Shipping_cost - this.items.Service_fee),
          })
        }
        console.log(arritem)
        // const arritem = [
        //   {
        //     code: 'TR-1001', description: 'ค่าขนส่งสินค้า', quantity: 1, payment: (this.items.payment - this.items.Shipping_cost - this.items.Service_fee),
        //   },
        //   // เพิ่มรายการอื่น ๆ ตามที่ต้องการ
        // ]
        if (this.items.Service_fee > 0) {
          // 'SE-1003'
          arritem.push({
            code: 'SE-1003',
            description: 'อื่น ๆ',
            quantity: 1,
            payment: this.items.Service_fee,
            difference: 0,
            sumitem: this.items.Service_fee,
          })
        }

        this.arritem = arritem
        this.texfullname = this.items.texfullname
        this.addresstex = this.items.addresstex
        this.telephone = this.items.telephone
        this.texnumber = this.items.texnumber
        console.log(this.items)
      } catch (e) {
        console.log(e)
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const obj = {
            texfullname: this.texfullname,
            addresstex: this.addresstex,
            telephone: this.telephone,
            texnumber: this.texnumber,
            // eslint-disable-next-line no-underscore-dangle
            _id: this.items._id,

          }
          this.$http.post('/Billpay/update', obj)
            .then(() => {
              this.getData()
              this.Success('บันทึกใบเสร็จเรียบร้อย')
            })
            .catch(err => {
              this.SwalError(err)
            })
        }
      })
    },
    Submitokay() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$bvModal.msgBoxConfirm('ต้องการบันทึกใบเสร็จ?', {
            title: 'ยืนยัน',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'warning',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          })
            .then(value => {
              if (value) {
                const NewDate = new Date()
                const obj = {
                  // eslint-disable-next-line no-underscore-dangle
                  _id: this.items._id,
                  statusbill: 1,
                  dateokay: NewDate,
                }
                this.$http.post('/Billpay/update', obj)
                  .then(() => {
                    this.getData()
                    this.Success('บันทึกใบเสร็จเรียบร้อย')
                  })
                  .catch(err => {
                    this.SwalError(err)
                  })
              }
            })
        }
      })
    },
    printbill() {
      try {
        window.open(`https://api.p2cargo.com/api/print/BillPdf/${this.ID}`)
        // window.open(`https://api.p2cargo.com/api/print/BillPdf/${this.ID}`)
      } catch (e) {
        console.log(e)
      }
    },
    submitprint() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let stringprint = ''
          // eslint-disable-next-line camelcase
          let check_print = null
          if (this.items.statusprint) {
            stringprint = 'บันทึกยังไม่พิมพ์?'
            // eslint-disable-next-line camelcase
            check_print = false
          } else {
            stringprint = 'บันทึกพิมพ์แล้ว?'
            // eslint-disable-next-line camelcase
            check_print = true
          }
          this.$bvModal.msgBoxConfirm(`${stringprint}`, {
            title: 'ยืนยัน',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            okTitle: 'YES',
            cancelTitle: 'NO',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true,
          }).then(result => {
            if (result) {
              const obj = {
                texfullname: this.texfullname,
                addresstex: this.addresstex,
                telephone: this.telephone,
                texnumber: this.texnumber,
                statusprint: check_print,
                // eslint-disable-next-line no-underscore-dangle
                _id: this.items._id,

              }
              this.$http.post('/Billpay/update', obj)
                .then(() => {
                  this.getData()
                  this.Success('บันทึกใบเสร็จเรียบร้อย')
                })
                .catch(err => {
                  this.SwalError(err)
                })
              // console.log(this.items.statusprint)
            }
          })
        }
      })
    },
    checkpayment(data) {
      let item = data.payment - data.Shipping_cost - data.Service_fee
      if (data.coupon) {
        item = (data.payment - data.Shipping_cost - data.Service_fee) + data.coupon.discount_coupon
      } else if (data.promotion) {
        item = (data.payment - data.Shipping_cost - data.Service_fee) + data.promotion.discount_pro
      } else if (data.coupon && data.promotion) {
        item = (data.payment - data.Shipping_cost - data.Service_fee) + data.promotion.discount_pro + data.coupon.discount_coupon
      }
      return item
    },
    // showdata(data) {
    //   if (data.Service_fee > 0) {
    //     const item = 'ค่าขนส่งสินค้า อื่น ๆ'
    //     return item
    //   }
    //   return 'ค่าขนส่งสินค้า'
    // },
    cancelsubmit() {
      this.$bvModal.msgBoxConfirm('ปรับเป็นรอตรวจสอบ?', {
        title: 'ยืนยัน',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'warning',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const obj = {
              // eslint-disable-next-line no-underscore-dangle
              _id: this.items._id,
              statusbill: 0,
              dateokay: null,
            }
            this.$http.post('/Billpay/update', obj)
              .then(() => {
                this.getData()
                this.Success('บันทึกใบเสร็จเรียบร้อย')
              })
              .catch(err => {
                this.SwalError(err)
              })
          }
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    GetOrderAll(type) {
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type,
      }
      this.$http
        .get('/order/orderAll', { params })
        .then(response => {
          // console.log(response.data)
          this.items = response.data.data
          // this.totalRows = this.items.length
          this.totalRows = response.data.total
        })
        .catch(error => console.log(error))
    },
    resize(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = 'auto'
      // eslint-disable-next-line no-param-reassign
      event.target.style.height = `${event.target.scrollHeight}px`
    },
    updateKeyword(id, note) {
      // console.log('Item ID:', id)
      // console.log('note:', note)
      const params = {
        id,
        note,
      }
      // console.log(params)
      this.$http
        .post('/order/updateNote', params)
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    showModal(data) {
      this.pic = data
      this.$refs['my-modal'].show()
    },
    downloadImg() {
      const imageURL = '@/assets/images/anan-img/example/1.jpg'
      const link = document.createElement('a')
      link.href = imageURL
      link.download = 'image.jpg'
      link.click()
    },

    convertToThaiBaht(amount) {
      // แบ่งเป็นส่วนบาทและสตางค์
      const baht = Math.floor(amount)
      const satang = Math.round((amount - baht) * 100)

      // แปลงส่วนบาทเป็นข้อความ
      const bahtText = this.numToText(baht)
      const satangText = this.numToText(satang)
      // สร้างข้อความแสดงเงินในรูปแบบบาทและสตางค์
      let result = `${bahtText}บาท`

      if (satang > 0) {
        result += `${satangText}สตางค์`
      }

      return result
    },
    numToText(num) {
      // อาเรย์ของข้อความเลขภาษาไทย
      const thaiNumText = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า']

      // อาเรย์ของข้อความหลัก
      const unitText = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน']

      // แปลงตัวเลขเป็นข้อความ
      let result = ''
      const numStr = num.toString()

      // console.log(itemnum[1])
      const len = numStr.length

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < len; i++) {
        // eslint-disable-next-line radix
        const digit = parseInt(numStr[i])
        if (digit !== 0) {
          if (digit === 1 && (len - i - 1) === 1) {
            result += 'สิบ'
            thaiNumText[digit] = 'เอ็ด'
          } else if (digit === 2 && (len - i - 1) === 1) {
            result += 'ยี่สิบ'
            thaiNumText[len - i - 1] = 'เอ็ด'
          } else {
            result += thaiNumText[digit]
            result += unitText[len - i - 1]
          }
        }
      }

      return result
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
.text-mobile {
  font-size: 10px !important;
}
}
</style>
